import React from "react";
import { Divider, Typography } from '@mui/material';


export const formatGOPPriceGroup = (patientRow) => {
        return (
            patientRow?.hospCases?.map(

                (hospCase, index) => {

                    const parsedPrice = parseFloat(hospCase.GOPPriceUSD).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    const parsedPriceTL = parseFloat(hospCase.GOPPriceTL / 100).toFixed(2).toString().split('.')
                    const priceStringAfterComma = parsedPriceTL[1];
                    const priceStringSplit = parsedPriceTL[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                    return (<div className="GOPPriceRowGroup">


                        <Typography className='RowPriceText'>
                            {`$${parsedPrice}`}
                        </Typography>
                    <Divider orientation="vertical" flexItem />

                        <Typography className='RowPriceText'>
                        {`${((hospCase.GOPPriceTL / 100) / hospCase.GOPPriceUSD).toFixed(1)}`}
                    </Typography>

                    <Divider orientation="vertical" flexItem />

                    <Typography className='RowPriceText'>
                        {`${priceStringSplit},${priceStringAfterComma}₺`}
                    </Typography>
                    </div>
                    )
                }
            )


        );
    }

