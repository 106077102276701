import { Tab, Tabs, useTheme } from "@mui/material";
import React, { useState } from "react";


export const DataTableAdminHeader = ({ selectedAdminTab, setSelectedAdminTab }) => {


    const theme = useTheme();

    const handleTabSelection = (e, newVal) => {
        setSelectedAdminTab(newVal);
    }


    return (<div className="InsuranceHeaderContainer">

        <div className="InsuranceUploadHeader">
            <Tabs value={selectedAdminTab} onChange={handleTabSelection} >
                <Tab label="Users" value={'Users'} />
                <Tab label="Permissions" value={'Permissions'} />

            </Tabs>
        </div>



    </div>);
}

