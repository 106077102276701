import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';

import { useNavigate, useSearchParams } from "react-router-dom";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
// Custom Components

// Custom Functions


// Data (JSON)


import { useTheme } from '@mui/material/styles';


import { useQueryClient } from '@tanstack/react-query';


import useLocalStorage from '../hooks/useLocalStorage';

import { useValidateQuery } from '../hooks/useValidateQuery';

import { CustomSnackbar } from '../components/CustomSnackbar';

import NavigationBar from '../components/NavigationBar';

import { styled } from '@mui/material/styles';

import { useRefreshQuery } from '../hooks/useRefreshQuery';
import { DataTable } from 'primereact/datatable';

import { Column } from 'primereact/column';
import { rowClass } from '../components/DataTable/ColumnFormats/rowClass';
import { Button, FormControl, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';

import TextLanguage from '../data/TextLanguage.json';
import { fileUploader } from '../functions/fileUploader';
import { useUploadInsDataMutation } from '../components/PatientFormContainer/pages/InsurancePage/hooks/useUploadInsDataMutation';
import { DataTableInsuranceHeader } from '../components/PatientFormContainer/pages/InsurancePage/DataTableInsuranceHeader';
import { useInsuredPatientListQuery } from '../components/PatientFormContainer/pages/InsurancePage/hooks/useInsuredPatientListQuery';
import LanguageSelector from '../components/LanguageSelector';
import Paginator from '../components/DataTable/Paginator';
import { useUserDataQuery } from '../components/Admin/hooks/useUserDataQuery';
import { InputText } from 'primereact/inputtext';
import { useUserDataMutation } from '../components/Admin/hooks/useUserDataMutation';

import LockResetIcon from '@mui/icons-material/LockReset';
import { useRBACDataQuery } from '../components/Admin/hooks/useRBACDataQuery';
import { DataTableAdminHeader } from '../components/Admin/DataTableAdminHeader';
import { useRBACDataMutation } from '../components/Admin/hooks/useRBACDataMutation';

//import "primereact/resources/themes/lara-light-indigo/theme.css";


//core
const FormField = styled(TextField)(({ theme }) => ({
    color: theme.palette.text.primary,
    background: 'none',
    borderRadius: 0,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '800px',

    '&:hover, &.Mui-focusVisible': {

        background: 'none',
        borderRadius: 0,
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
    },
    '.MuiInputBase-formControl': {
        paddingInline: '0.25rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },

    '.MuiInputBase-inputMultiline': {
        paddingInline: '0.5rem 0.5rem !important',
        paddingBlock: '0.25rem !important'
    },
    '.MuiInputBase-root': {
        '&:before': {
            borderBottom: 'none !important'
        }
    },
    '.MuiSelect-select': {
        border: 'none !important',
        paddingInline: '0.5rem 2.5rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },
    '.MuiFormHelperText-root': { color: theme.palette.text.primary },
}));

function remToPx(rem) {
    return (rem * parseFloat(getComputedStyle(document.documentElement).fontSize))
}


const policyDateBody = (rowData) => {

    const dateStart = new Date(rowData.policyStart);
    const dateEnd = new Date(rowData.policyEnd);

    return (
        <Typography>{`${dateStart.getDate() - 1}/${dateStart.getMonth() + 1}/${dateStart.getFullYear()} - ${dateEnd.getDate() - 1}/${dateEnd.getMonth() + 1}/${dateEnd.getFullYear()}`}</Typography>
    )
}
const dobBody = (rowData) => {

    const date = new Date(rowData.dob);

    return (
        <Typography>{`${date.getDate() - 1}/${date.getMonth() + 1}/${date.getFullYear()}`}</Typography>
    )
}


function AdminPage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();
    const [opMode, setopMode] = useState(false);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);



    useEffect(() => {

        queryClient.invalidateQueries({ queryKey: ['patients'] });
        queryClient.invalidateQueries({ queryKey: ['validate'] });
        queryClient.invalidateQueries({ queryKey: ['activityLog'] });

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [parsedList, setParsedList] = useState([]);



    // Context Menu End


    const navigate = useNavigate();


    const [confirmedUserID, setconfirmedUserID] = useState(null);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
            queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])



    useEffect(() => {
        if (validateQuery.isSuccess) {
            setconfirmedUserID(validateQuery.data.data?.userID);

            if (["Admin"].includes(validateQuery.data.data?.userID)) {
                setopMode(true);
            } else {
                setopMode(false);
                navigate("/");
            }
        }

    }, [validateQuery.data, validateQuery.isSuccess, validateQuery.isError])

    const datatableRef = useRef(null);

    const [selectedInsData, setSelectedInsData] = useState('VUSO');
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 25;

    const insuredPatientListQuery = useInsuredPatientListQuery({ insuranceCo: selectedInsData, page: page, limit: pageSize });

    const [uploadMode, setUploadMode] = useState(false);

    const [selectedUserType, setSelectedUserType] = useState('Operator');
    const userDataQuery = useUserDataQuery({ selectedUserType });

    const rbacDataQuery = useRBACDataQuery();

    const userDataMutation = useUserDataMutation({ setsnackbarStatus });

    const handlePasswordReset = (rowData) => () => {

        userDataMutation.mutate({ newData: rowData, username: rowData?.username, reset: true });
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const onRowEditComplete = (e) => {
        console.log(e);
        let { newData, index } = e;

        userDataMutation.mutate({ newData: newData, username: e?.data?.username, reset: false });
    };

    const [selectedAdminTab, setSelectedAdminTab] = useState('Users');

    const userDataMode = selectedAdminTab === 'Users';
    const rbacDataMode = selectedAdminTab === 'Permissions';

    const rbacDataMutation = useRBACDataMutation({ setsnackbarStatus });

    const handlePermissionFlip = (rowData, permission) => () => {
        console.log(rowData, permission);
        const newPermissions = [...rowData.permissions];
        const permissionIndex = newPermissions.indexOf(permission);
        if (permissionIndex > -1) {
            newPermissions.splice(permissionIndex, 1);
        } else {
            newPermissions.push(permission);
        }
        const newData = { ...rowData, permissions: newPermissions };
        rbacDataMutation.mutate({ newData: newData, role: rowData?.role });
    }

    const permissionsBody = (rowData) => {
        const borderRadius = '12px';
        const permissionAmount = rbacDataQuery.data?.data.allPermissionTypes.length;
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            {rbacDataQuery.data?.data.allPermissionTypes.map((perm, index) => {
                return (<Tooltip
                    title={perm}
                    placement='top'
                    arrow
                    key={index}
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -8],
                                    },
                                },
                            ],
                        },
                    }}
                >
                    <Button
                        onClick={handlePermissionFlip(rowData, perm)}

                        sx={{
                            height: '20px', width: '30px',
                            borderRadius: index === 0 ? `${borderRadius} 0px 0px ${borderRadius}` : index === permissionAmount - 1 ? `0px ${borderRadius} ${borderRadius} 0px` : '0px',
                            border: `1px solid ${theme.palette.dark[1]}`,
                            boxShadow: '0px 0px 8px 2px rgba(0,0,0,0.2)',
                            background: rowData.permissions.includes(perm) ?
                                theme.palette.mainColor.Sat
                                :
                                theme.palette.mainColor.Dark,
                            cursor: 'pointer',
                            '&:hover': {
                                background: theme.palette.gradient.glowButton

                            }
                        }} />
                </Tooltip>)


            })}
        </div>

    };

    const sessionDisplayBody = ((rowData) => {
        const date = new Date(rowData.session.latestActivity);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const parsedDate = `${day}/${month} - ${hours}:${minutes}`;
        return (

            <div style={{ display: 'flex', paddingInline: '1.3rem' }}>
                <Tooltip
                    arrow
                    title={rowData.session.active ? `${parsedDate}` : 'Inactive'}
                    placement='top'
                >
                <div style={{
                    borderRadius: '100%',
                    height: '15px', width: '15px',
                    background: rowData.session.active ?
                        `radial-gradient(circle, ${theme.palette.successColor.light} 0%, ${theme.palette.successColor.dark} 100%)`
                        :
                        `radial-gradient(circle, ${theme.palette.successColor.dark} 35% , ${theme.palette.shadow[0]} 100% )`,
                    boxShadow: rowData.session.active ? `0px 0px 4px 0px ${theme.palette.successColor.light}` : '0px 0px 6px 0px rgba(0,0,0,0.5)',
                }} />
            </Tooltip>
                </div >

        )
}

    )

return (

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

        <div className="App-main">

            <CustomSnackbar
                snackbarStatus={snackbarStatus}
                setsnackbarStatus={setsnackbarStatus}
            />

            <NavigationBar
                currentPage='admin'
                lang={lang}
                opMode={opMode}
            />

            <div className='dashboardPageContainer' >

                <DataTable

                    id='divTable'
                    ref={datatableRef}

                    stripedRows
                    scrollable
                    scrollHeight="flex"
                    rowClassName={rowClass({ opMode, confirmedUserID })}
                    resizableColumns

                    header={<DataTableAdminHeader
                        selectedAdminTab={selectedAdminTab}
                        setSelectedAdminTab={setSelectedAdminTab}
                    />}
                    size="small"
                    dataKey={userDataMode ? "username" : "role"}
                    className="DataGrid"
                    value={

                        userDataMode ?
                            userDataQuery.data?.data.userData
                            :
                            rbacDataQuery.data?.data.rbacData
                            || []

                    }
                    style={{ width: '100%' }}
                    tableStyle={{ width: '100%' }}
                    editMode='row'
                    onRowEditComplete={onRowEditComplete}
                >
                    {userDataMode && <Column field={'session'} header={'Session'} headerStyle={{ width: '1%', minWidth: '120px', height: '1rem' }}
                        body={sessionDisplayBody}

                    />}

                    {userDataMode && <Column field={'username'} header={'User Name'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}

                        body={(rowData) => <Typography>{rowData['username']}</Typography>}
                        editor={(options) => textEditor(options)}
                    />}
                    {userDataMode && <Column field={'userLabel'} header={'User Label'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}

                        body={(rowData) => <Typography>{rowData['userLabel']}</Typography>}
                        editor={(options) => textEditor(options)}
                    />}
                    {userDataMode && <Column field={'userID'} header={'UserID'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}

                        body={(rowData) => <Typography>{rowData['userID']}</Typography>}
                        editor={(options) => textEditor(options)}
                    />}
                    {userDataMode && <Column field={'type'} header={'Type'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}
                        body={(rowData) => <Typography>{rowData['type']}</Typography>}
                        editor={(options) => textEditor(options)}
                    />}
                    {userDataMode && <Column field={'password'} header={'Password'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}
                        body={(rowData) => <Button
                            sx={{
                                background: 'transparent',
                                border: 'none',

                                cursor: 'pointer',
                                color: theme.palette.primary.light,
                                '&:hover': {
                                    color: theme.palette.primary.dark,
                                    background: 'transparent',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    border: 'none'
                                }

                            }}
                            onClick={handlePasswordReset(rowData)}>
                            <LockResetIcon />
                        </Button>}
                        editor={(options) => textEditor(options)}
                    />}



                    {userDataMode && <Column rowEditor={true} headerStyle={{ width: '0.001%', minWidth: '120px', maxWidth: '100px' }} bodyStyle={{ textAlign: 'center' }}></Column>}


                    {rbacDataMode && <Column field={'role'} header={'Role'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }} />}

                    {rbacDataMode &&
                        <Column field={'permissions'} header={'Permissions'} headerStyle={{ width: '10%', minWidth: '120px', height: '1rem' }}

                            body={permissionsBody}

                        />}


                </DataTable>

            </div>


        </div>

    </LocalizationProvider>










);
}

export default AdminPage;
