import React from "react";
import { Typography } from '@mui/material';

export const formatAvgTLPayments = (hospCaseRow) => {

    const parsedPriceAvg = parseFloat((hospCaseRow.GOPTotalTL / 100) / hospCaseRow.totalCount).toFixed(2).toString().split('.')
    const AvgPriceStringAfterComma = parsedPriceAvg[1];
    const AvgPriceStringSplit = parsedPriceAvg[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

        <Typography className='RowPriceText'>
            {`${AvgPriceStringSplit},${AvgPriceStringAfterComma}₺`}
        </Typography>
    </div>
    );
}
