import React from "react";
import { Typography, Divider } from '@mui/material';

export const formatGOPPriceGroupPayments = (hospCaseRow) => {

    const parsedPrice = parseFloat(hospCaseRow.GOPTotalUSD).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const parsedPriceTL = parseFloat(hospCaseRow.GOPTotalTL / 100).toFixed(2).toString().split('.')

    const priceStringAfterComma = parsedPriceTL[1];
    const priceStringSplit = parsedPriceTL[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const parsedPriceAvg = parseFloat(hospCaseRow.GOPTotalUSD / hospCaseRow.totalCount).toFixed(2).toString().split('.')
    const AvgPriceStringAfterComma = parsedPriceAvg[1];
    const AvgPriceStringSplit = parsedPriceAvg[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return (<div className="GOPPriceRowGroup">


        <Typography className='RowPriceText'>
            {`$${parsedPrice}`}
        </Typography>
        <Divider orientation="vertical" flexItem />

        <Typography className='RowPriceText'>
            {`${((hospCaseRow.GOPTotalTL / 100) / hospCaseRow.GOPTotalUSD).toFixed(1)}`}
        </Typography>

        <Divider orientation="vertical" flexItem />

        <Typography className='RowPriceText'>
            {`${priceStringSplit},${priceStringAfterComma}₺`}
        </Typography>

        <Divider orientation="vertical" flexItem />

        {
            /*
            <Typography className='RowPriceText'>
            {`$`}
        </Typography>
        <Typography className='RowPriceText'>
            {`${AvgPriceStringSplit},${AvgPriceStringAfterComma}`}
        </Typography>
*/
}
    </div>
    )








}
