import React, { useEffect, useRef, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/tr';


import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate, useSearchParams } from "react-router-dom";



// Custom Components

// Custom Functions

import { useExchangeRateQuery } from '../hooks/useExchangeRateQuery';

// Data (JSON)

import TextLanguage from '../data/TextLanguage.json';

import Papa, { parse } from 'papaparse';

import { useTheme } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';

import { useQueryClient } from '@tanstack/react-query';

// Column Formats

import { formatCaseNo } from "../components/DataTable/ColumnFormats/formatCaseNo";
import { formatDateAdm } from "../components/DataTable/ColumnFormats/formatDateAdm";
import { formatInsuranceCompany } from "../components/DataTable/ColumnFormats/formatInsuranceCompany";
import { formatLDMCaseNo } from "../components/DataTable/ColumnFormats/formatLDMCaseNo";

import { formatGOPPriceGroup } from '../components/DataTable/ColumnFormats/formatGOPPriceGroup';

import { formatPatientName } from "../components/DataTable/ColumnFormats/formatPatientName";
import { formatHospitalUserIDPayments } from "../components/DataTable/ColumnFormats/formatHospitalUserIDPayments";
import { formatHospitalUserID } from '../components/DataTable/ColumnFormats/formatHospitalUserID';
import { formatDateDeadline } from '../components/DataTable/ColumnFormats/formatDateDeadline';

import { formatGOPPriceGroupPayments } from '../components/DataTable/ColumnFormats/formatGOPPriceGroupPayments';

import { formatAvgUSDPayments } from '../components/DataTable/ColumnFormats/formatAvgUSDPayments';
import { formatAvgTLPayments } from '../components/DataTable/ColumnFormats/formatAvgTLPayments';
import { formatPatientCountPayments } from '../components/DataTable/ColumnFormats/formatPatientCountPayments';
import { formatOutPatientCountPayments } from '../components/DataTable/ColumnFormats/formatOutPatientCountPayments';
import { formatOutPatientInClinicCountPayments } from '../components/DataTable/ColumnFormats/formatOutPatientInClinicCountPayments';
import { formatInPatientCountPayments } from '../components/DataTable/ColumnFormats/formatInPatientCountPayments';
import { GOPPriceGroupHeader } from '../components/DataTable/ColumnHeaders/GOPPriceGroupHeader';


import { rowClass } from '../components/DataTable/ColumnFormats/rowClass';


import useLocalStorage from '../hooks/useLocalStorage';
import { usePaymentListQuery } from '../hooks/usePaymentListQuery';
import { useValidateQuery } from '../hooks/useValidateQuery';
import { useActivePaymentListQuery } from '../hooks/useActivePaymentListQuery';
import { CustomSnackbar } from '../components/CustomSnackbar';
import { DataTablePaymentFooter } from '../components/DataTable/components/DataTablePaymentFooter';
import { DataTablePaymentHeader } from '../components/DataTable/components/DataTablePaymentHeader';
import { useHospitalUserListQuery } from '../hooks/useHospitalUserListQuery';


import { useArchivePaymentListQuery } from '../hooks/useArchivePaymentListQuery';
import { useArchivePaymentListGroupQuery } from '../hooks/useArchivePaymentListGroupQuery';
import { PaymentListCreator } from '../components/PaymentListCreator';
import { useUploadReceiptMutation } from '../hooks/useUploadReceiptMutation';
import FileDownload from '@mui/icons-material/FileDownload';

import { useParsedPaymentListGroupQuery } from '../hooks/useParsedPaymentListGroupQuery';



import NavigationBar from '../components/NavigationBar';
import { useArchivedHospPatientsQuery } from '../hooks/useArchivedHospPatientsQuery';
import { useHospitalTokenListQuery } from '../hooks/useHospitalTokenListQuery';

//import "primereact/resources/themes/lara-light-indigo/theme.css";

import uploadTypes from '../data/uploadTypes';

//core

import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useInsuranceCompanyListQuery } from '../hooks/useInsuranceCompanyListQuery';
import { fileUploader } from '../functions/fileUploader';
import { useRefreshQuery } from '../hooks/useRefreshQuery';
import { useParsedPaymentListHospQuery } from '../hooks/useParsedPaymentListHospQuery';


export const DownloadButton = styled(Button)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    minWidth: '25px',
    minHeight: '25px',
    padding: '5px',
    margin: '0px',
    zIndex: 1500,
    background: 'rgb(30, 94, 28)',
    border: 'none',
    outline: '1px solid #787878',
    boxShadow: 'none',
    color: 'white',
    borderRadius: '0px',
    gap: '0.25rem',
    '&:hover': {
        background: 'rgb(30, 137, 26)',
        boxShadow: 'none',
        outline: '1px solid white',
        border: 'none',
    },


}));




function PaymentPage() {

    const theme = useTheme();

    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    const queryClient = useQueryClient();
    const [opMode, setopMode] = useState(false);

    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const hospitalUserListQuery = useHospitalUserListQuery()
    const insCoListQuery = useInsuranceCompanyListQuery({ opMode });
    const hospitalTokenListQuery = useHospitalTokenListQuery();

    // Context Menu need to fix InsuranceStart and InsuranceEnd, add diagnosis and caseType for the processing stage
    const contextMenuRef = useRef(null);
    const [rightselectedRow, setrightselectedRow] = useState(null);


    const [selectedRows, setselectedRows] = useState(null);

    const menuModel = [

        {
            label: 'Copy',
            icon: <FileCopyIcon fontSize='small' sx={{ mr: '5px' }} />,
            items: [
                { label: 'Copy Row', command: () => copyToClipboard(rightselectedRow) },
                { label: 'Copy All', command: () => copyToClipboardArray(selectedRows) },

            ]
        },
        {
            label: 'Export',
            icon: <FileDownloadIcon fontSize='small' sx={{ mr: '5px' }} />,
            items: [
                {
                    label: 'Patient Information',
                    // command: () => exportWidgetCreator('Patient'),
                    items: [
                        { label: 'to CSV', command: () => exportCSV(selectedRows, 'Patient') },
                        { label: 'to XLSX', command: () => exportExcel(selectedRows, 'Patient') },

                    ]
                },
                {
                    label: 'Hospital Payment List',
                    items: [
                        { label: 'to CSV', command: () => exportCSV(selectedRows, 'Hospital') },
                        { label: 'to XLSX', command: () => exportExcel(selectedRows, 'Hospital') },

                    ]
                },
                {
                    label: 'Insurance Payroll',
                    items: [
                        { label: 'to CSV', command: () => exportCSV(selectedRows, 'Insurance') },
                        { label: 'to XLSX', command: () => exportExcel(selectedRows, 'Insurance') },

                    ]
                },
            ]
        },

        { separator: true },
        { label: 'Clear Selection', command: () => setselectedRows([]) },
    ];
    const copyToClipboardArray = (rowsData) => {
        console.log(rowsData);
        let clipboardData = [`Admission Date\tRBKCaseNo\tLDMCaseNo\tPatient Name\tDate of Birth\tFranchise\tPolicy Number\tInsurance Period Start\tInsurance Period End\tHotel\tDiagnosis\tCase Type\/Total Fee\tPrice (USD)\tOur Fee\tInsurance Company\tHospital Name`];
        rowsData.forEach(rowData => {
            const clipboardDataRow = `${rowData?.admissionDate ? rowData?.admissionDate : ''}\t${rowData?.RBKCaseNo ? rowData?.RBKCaseNo : ''}\t${rowData?.LDMCaseNo ? rowData?.LDMCaseNo : ''}\t${rowData?.name ? rowData?.name : ''}\t${rowData?.dob ? rowData?.dob : ''}\t${rowData?.franchise ? rowData?.franchise : ''}\t${rowData?.policyNo ? rowData?.policyNo : ''}\t${rowData?.InsurancePeriodStart ? rowData?.InsurancePeriodStart : ''}\t${rowData?.InsurancePeriodEnd ? rowData?.InsurancePeriodEnd : ''}\t${rowData?.hotel ? rowData?.hotel : ''}\t${rowData?.diagnosis ? rowData?.diagnosis : ''}\t${rowData?.caseType ? rowData?.caseType : ''}\t${rowData?.insuranceBill ? rowData?.insuranceBill : ''}\t${rowData?.GOPPriceUSD ? rowData?.GOPPriceUSD : ''}\t${(rowData?.insuranceBill - rowData?.GOPPriceUSD) ? (rowData?.insuranceBill - rowData?.GOPPriceUSD) : ''}\t${rowData?.InsuranceCo ? rowData?.InsuranceCo : ''}\t${hospitalUserListQuery?.data?.data.find(hosp => rowData?.userID === hosp.value)?.label ? hospitalUserListQuery?.data?.data.find(hosp => rowData?.userID === hosp.value)?.label : ''}`;
            clipboardData.push(clipboardDataRow);
        });
        navigator.clipboard.writeText(clipboardData.join('\n'));
    }

    const copyToClipboard = (rowData) => {


        const clipboardData = `${rowData?.admissionDate ? rowData?.admissionDate : ''}\t${rowData?.RBKCaseNo ? rowData?.RBKCaseNo : ''}\t${rowData?.name ? rowData?.name : ''}\t${rowData?.dob ? rowData?.dob : ''}\t${rowData?.franchise ? rowData?.franchise : ''}\t${rowData?.policyNo ? rowData?.policyNo : ''}\t${rowData?.InsurancePeriodStart ? rowData?.InsurancePeriodStart : ''}\t${rowData?.InsurancePeriodEnd ? rowData?.InsurancePeriodEnd : ''}\t${rowData?.hotel ? rowData?.hotel : ''}\t${rowData?.diagnosis ? rowData?.diagnosis : ''}\t${rowData?.caseType ? rowData?.caseType : ''}\t${rowData?.insuranceBill ? rowData?.insuranceBill : ''}\t${rowData?.GOPPriceUSD ? rowData?.GOPPriceUSD : ''}\t${(rowData?.insuranceBill - rowData?.GOPPriceUSD) ? (rowData?.insuranceBill - rowData?.GOPPriceUSD) : ''}\t${rowData?.InsuranceCo ? rowData?.InsuranceCo : ''}\t${hospitalUserListQuery?.data?.data.find(hosp => rowData?.userID === hosp.value)?.label ? hospitalUserListQuery?.data?.data.find(hosp => rowData?.userID === hosp.value)?.label : ''}`;

        navigator.clipboard.writeText(clipboardData);
    }



    const handleTableDownloadButton = (patientRow) => () => {

        console.log(patientRow);


    }


    // Context Menu End

    const navigate = useNavigate();
    const [searchFilter, setSearchFilter] = useState({});



    const [searchParams, setSearchParams] = useSearchParams({ page: 0, pageSize: 25, currentTab: 0 });

    const page = parseInt(searchParams.get('page')) || 0;
    const pageSize = parseInt(searchParams.get('pageSize')) || 25;
    const currentTab = parseInt(searchParams.get('currentTab')) || 0;


    // Paginator ---





    const [confirmedUserID, setconfirmedUserID] = useState(null);

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
            queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])


    useEffect(() => {
        if (validateQuery.isSuccess) {
            setconfirmedUserID(validateQuery.data.data?.userID);

            if (["Operator", "Admin", "Expertise"].includes(validateQuery.data.data?.userID)) {
                setopMode(true);
            } else {
                setopMode(false);


                setSearchParams(prev => {
                    prev.set('currentTab', 3);
                    return prev;
                });

                setselectedArchiveHospitalID(validateQuery.data.data?.userID);
            }

            setloadingPatientsList(false);
        }
    }, [validateQuery.data, validateQuery.isSuccess])




    const PaymentListPatientsQuery = usePaymentListQuery({
        opMode: opMode,
        page: page,
        pageSize: pageSize,
        //sortField: sortField,
        //sortOrder: sortOrder,
        searchFilter: searchFilter,
    });


    const [activePaymentList, setActivePaymentList] = useState([]);
    const [ActivePaymentState, setActivePaymentState] = useState(false);



    const exchangeRateQuery = useExchangeRateQuery({ opMode });

    useEffect(() => {
        if (exchangeRateQuery.isError && exchangeRateQuery?.error?.response?.status !== 401) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Exchange Rate`,
                    severity: 'error'
                }
            )

        }
    }, [exchangeRateQuery.data, exchangeRateQuery.isError]);


    useEffect(() => {



        if (!opMode && confirmedUserID) {
            setselectedArchiveHospitalID(confirmedUserID);
        }


        // Clean up the event listener when the component is unmounted



    }, [])




    const datatableRef = useRef(null);

    const [patientList, setpatientList] = React.useState([]);
    const [patientDashboard, setPatientDashboard] = React.useState([]);






    const exportExcel = (inputRows, format) => {

        let excelData = null;
        if (inputRows?.length > 0) {

            if (format === 'Hospital') {

                const flattenedData = inputRows.flatMap(patientEntry => {
                    return patientEntry?.hospCases?.map(hospCase => ({
                        caseNumber: patientEntry.caseNumber,
                        RBKCaseNo: patientEntry.RBKCaseNo,
                        LDMCaseNo: patientEntry.LDMCaseNo,
                        admissionDate: patientEntry.admissionDate,
                        createdAt: patientEntry.createdAt,
                        dob: patientEntry.dob,
                        mobilePhone: patientEntry.mobilePhone,
                        policyNo: patientEntry.policyNo,
                        InsuranceCo: patientEntry.InsuranceCo,
                        InsurancePeriodStart: patientEntry.InsurancePeriodStart,
                        InsurancePeriodEnd: patientEntry.InsurancePeriodEnd,
                        notes: patientEntry.notes,
                        hotel: patientEntry.hotel,
                        hotelRoom: patientEntry.hotelRoom,
                        complaints: patientEntry.complaints,
                        diagnosis: patientEntry.diagnosis,
                        caseType: patientEntry.caseType,
                        name: patientEntry.name,
                        franchise: patientEntry.franchise,
                        userID: hospCase.userID,
                        GOPPriceUSD: hospCase.GOPPriceUSD,
                        GOPPriceTL: hospCase.GOPPriceTL,
                        GOPPriceExr: hospCase.GOPPriceExr,
                        GOP_Date: hospCase.GOP_Date,
                        Proforma_Date: hospCase.Proforma_Date,
                        Invoice_Date: hospCase.Invoice_Date,


                    }));
                });
                console.log(flattenedData)
                const groupedHospData = flattenedData.reduce((acc, hospCase) => {
                    const { userID, GOPPriceUSD, GOPPriceExr, GOPPriceTL } = hospCase;
                    let GOPTLCalc = 0;
                    if (!GOPPriceTL) {
                        GOPTLCalc = parseFloat(GOPPriceUSD * GOPPriceExr).toFixed(2);
                    } else {
                        GOPTLCalc = GOPPriceTL.toFixed(2);
                    }
                    console.log(GOPTLCalc)

                    if (!acc[userID]) {

                        acc[userID] = { userID: userID, GOPTotalUSD: GOPPriceUSD, GOPTotalTL: GOPTLCalc };
                    } else {

                        acc[userID].GOPTotalUSD += GOPPriceUSD;
                        acc[userID].GOPTotalTL += GOPTLCalc;
                    }

                    return acc;
                }, {});

                const groupedHospArray = Object.values(groupedHospData);

                excelData = groupedHospArray?.map((hospitalEntry) => {
                    return {
                        'Hospital': hospitalUserListQuery?.data?.data.find((hosp) => hospitalEntry.userID === hosp.value)?.label,
                        'USD': hospitalEntry.GOPTotalUSD,
                        'KUR': parseFloat(parseFloat(hospitalEntry.GOPTotalTL) / hospitalEntry.GOPTotalUSD).toFixed(2),
                        'TL': parseFloat(hospitalEntry.GOPTotalTL).toFixed(2),
                    };
                });


            } else if (format === 'Insurance') {

                // excelData = ...
            }



            import('xlsx').then((xlsx) => {
                const worksheet = xlsx.utils.json_to_sheet(excelData); // Apply Filters here for filtered export!!!
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });

                saveAsFile(excelBuffer, 'patientList', 'xlsx');
            });

        }
    };

    const saveAsFile = (buffer, fileName, type) => {
        import('file-saver').then((module) => {
            if (module && module.default) {

                if (type === 'xlsx') {
                    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    let EXCEL_EXTENSION = '.xlsx';
                    const data = new Blob([buffer], { type: EXCEL_TYPE });

                    module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
                } else if (type === 'csv') {
                    const data = new Blob([buffer], { type: 'text/csv;charset=utf-8' });

                    module.default.saveAs(data, fileName + new Date().getTime() + '.csv');
                }
            }
        });
    };


    const exportCSV = (inputRows, format) => {

        let csvData = null;
        if (inputRows?.length > 0) {

            if (format === 'Hospital') {

                // Flatten data per hospital (Omit files,comms? this shouldnt go on the archive anyway  )

                const flattenedData = inputRows.flatMap(patientEntry => {
                    return patientEntry?.hospCases?.map(hospCase => ({
                        caseNumber: patientEntry.caseNumber,
                        RBKCaseNo: patientEntry.RBKCaseNo,
                        LDMCaseNo: patientEntry.LDMCaseNo,
                        admissionDate: patientEntry.admissionDate,
                        createdAt: patientEntry.createdAt,
                        dob: patientEntry.dob,
                        mobilePhone: patientEntry.mobilePhone,
                        policyNo: patientEntry.policyNo,
                        InsuranceCo: patientEntry.InsuranceCo,
                        InsurancePeriodStart: patientEntry.InsurancePeriodStart,
                        InsurancePeriodEnd: patientEntry.InsurancePeriodEnd,
                        notes: patientEntry.notes,
                        hotel: patientEntry.hotel,
                        hotelRoom: patientEntry.hotelRoom,
                        complaints: patientEntry.complaints,
                        diagnosis: patientEntry.diagnosis,
                        caseType: patientEntry.caseType,
                        name: patientEntry.name,
                        franchise: patientEntry.franchise,
                        userID: hospCase.userID,
                        GOPPriceUSD: hospCase.GOPPriceUSD,
                        GOPPriceTL: hospCase.GOPPriceTL,
                        GOPPriceExr: hospCase.GOPPriceExr,
                        GOP_Date: hospCase.GOP_Date,
                        Proforma_Date: hospCase.Proforma_Date,
                        Invoice_Date: hospCase.Invoice_Date,
                    }));
                });
                console.log(flattenedData)
                const groupedHospData = flattenedData.reduce((acc, hospCase) => {
                    const { userID, GOPPriceUSD, GOPPriceExr, GOPPriceTL } = hospCase;


                    if (!acc[userID]) {

                        acc[userID] = { userID: userID, GOPTotalUSD: GOPPriceUSD, GOPTotalTL: GOPPriceTL };
                    } else {

                        acc[userID].GOPTotalUSD += GOPPriceUSD;
                        acc[userID].GOPTotalTL += GOPPriceTL;
                    }

                    return acc;
                }, {});

                const groupedHospArray = Object.values(groupedHospData);

                const exportList = groupedHospArray?.map((hospitalEntry) => {
                    return {
                        'Hospital': hospitalUserListQuery?.data?.data.find((hosp) => hospitalEntry.userID === hosp.value)?.label,
                        'USD': hospitalEntry.GOPTotalUSD,
                        'KUR': parseFloat(parseFloat(hospitalEntry.GOPTotalTL) / hospitalEntry.GOPTotalUSD).toFixed(2),
                        'TL': parseFloat(hospitalEntry.GOPTotalTL).toFixed(2),
                    };
                });

                // Convert data to CSV format using papaparse
                csvData = Papa.unparse(exportList, {
                    header: true,
                });

            } else if (format === 'Insurance') {
                let hospitalString = [];  // should be like ex: Alanya Yasam Hospital (eski Ozel Hayat Hastanesi) - 55.00; Konakli Medical Center 0242 565 37 82-38 34 /0533 477 83 59 - 35.00
                inputRows?.hospCases?.forEach(hospCase => {

                    hospitalString.push(hospitalUserListQuery?.data?.data.find((hosp) => hospCase.userID === hosp.value)?.label);
                    hospitalString.push(hospCase.GOPPriceUSD);
                    hospitalString.join(' - ')
                }
                );

                hospitalString.join('; ');

                const exportList = inputRows?.map((patient) => {
                    return {
                        'RBK Case Number': patient.RBKCaseNo,
                        'Patient Name': patient.name,
                        'Insurance Company': patient.InsuranceCo,
                        'Hospital': hospitalString,
                        'Admission Date': patient.admissionDate,
                        'Franchise': patient.franchise,
                        'Policy No': patient.policyNo,
                    };
                });

                csvData = Papa.unparse(exportList, {
                    header: true,
                });


            }
            // ========================================

            saveAsFile(csvData, 'patientList.csv', 'csv');
        }

    };

    const [loadingPatientsList, setloadingPatientsList] = useState(false);

    const [filterToggle, setFilterToggle] = useState(true);



    const ActivePaymentQuery = useActivePaymentListQuery({ opMode });


    useEffect(() => {
        if (ActivePaymentQuery.isSuccess) {
            console.log(ActivePaymentQuery.data);
            if (ActivePaymentQuery?.data?.status === 200 && ActivePaymentQuery?.data?.data?.paymentList) {

                setActivePaymentState(ActivePaymentQuery?.data?.data?.paymentList.every((entry) => { return (entry?.receiptData) }));

                setActivePaymentList(ActivePaymentQuery?.data?.data?.paymentList);

            } else if (ActivePaymentQuery?.data?.status === 406) { // no active paymentList, disable complete button
                setActivePaymentState(false);
                setActivePaymentList([]);
                console.log("No Active Payment List");
            } else {
                setActivePaymentState(false);
                setActivePaymentList([]);
                //  throw new Error('Network response was not OK');
            }
        }
        if (ActivePaymentQuery.isError && ActivePaymentQuery?.error?.response?.status !== 406) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Active List`,
                    severity: 'error'
                }
            )
        }
    }, [ActivePaymentQuery.data, ActivePaymentQuery.isSuccess, ActivePaymentQuery.isError])







    useEffect(() => {

        setselectedRows([]);
        setselectedActiveHospitalID(null);
        opMode && setselectedArchiveHospitalID(null);


        switch (currentTab) {
            case 0:
                setSearchFilter({ ...searchFilter, processed: false });

                queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'] });
                break;
            case 1:
                setSearchFilter({ ...searchFilter, processed: true, onActivePaymentList: false });
                queryClient.invalidateQueries({ queryKey: ['PaymentListPatients'] });
                break;
            case 2:
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'] });
                break;
            default:
            case 3:
                queryClient.invalidateQueries({ queryKey: ['ActivePaymentList'] });
                break;

        }
        console.log('filter:', searchFilter);
    }, [currentTab]) // on   currentTab change, update filters

    useEffect(() => {
        if (PaymentListPatientsQuery.isSuccess) {

            if ([0, 1].includes(currentTab)) {

                setpatientList(PaymentListPatientsQuery.data.data.patientList);
            }


            setPatientDashboard(PaymentListPatientsQuery.data.data.dashboard);
        }
        if (PaymentListPatientsQuery.isError) {

            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Patients`,
                    severity: 'error'
                }
            )
        }
    }, [PaymentListPatientsQuery.data, PaymentListPatientsQuery.isSuccess, PaymentListPatientsQuery.isError])

    /*
        const formatDocDownloader = (patientRow) => {

            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', zIndex: 20 }}>
                    <Button onClick={handleTableDownloadButton(patientRow)} className='DocDownloadButton'
                        disableRipple={true}
                        sx={{
                            width: 'auto', height: 'auto', minWidth: '25px', minHeight: '25px', p: '5px', m: '0px', zIndex: 25,
                            background: 'transparent', border: 'none', outline: 'none', boxShadow: 'none', color: 'white',
                            '&:hover': {
                                background: 'transparent',
                                boxShadow: 'none',
                                outline: 'none',
                                border: 'none',
                            }
                        }}
                    >
                        <FileDownloadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
                    </Button>

                </div>
            )

        };
    */

    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadReceiptMutation = useUploadReceiptMutation({ setsnackbarStatus, setUploadProgress })




    const parsedPaymentListGroupQuery = useParsedPaymentListGroupQuery();
    const [parsedSelectedHospital, setparsedSelectedHospital] = useState('');
    const parsedPaymentListHospQuery = useParsedPaymentListHospQuery({ selectedHospital: parsedSelectedHospital });


    const [selectedPaymentID, setselectedPaymentID] = useState(null);
    const archivePaymentListQuery = useArchivePaymentListQuery({ selectedPaymentID: selectedPaymentID });
    const [selectedFilterUserID, setselectedFilterUserID] = useState(null);
    const archivePaymentListGroupQuery = useArchivePaymentListGroupQuery({ selectedFilterUserID });




    const invoiceButtonTemplate = (data, index) => {
        const invoiceFile = data?.hospCases[0]?.files?.find(file => file?.filetype === 'invoice');
        const userID = data?.hospCases[0]?.userID;

        const sasToken = hospitalTokenListQuery?.data?.data?.find(hosp => userID === hosp.userID)?.sasToken;
        const fileURL = `${invoiceFile?.blobUrl}?${sasToken}`;

        return (<Button key={`downloadInvoiceButton-${data?.RBKCaseNo}-${index}`} href={fileURL} className='DocDownloadButton'
            disableRipple={true}
            sx={{
                width: '100%', height: 'auto', minWidth: '40px', minHeight: '25px', p: '5px', m: '0px', zIndex: 1500,
                background: 'rgb(30, 94, 28)', border: 'none', outline: '1px solid #787878', boxShadow: 'none', color: 'white',
                '&:hover': {
                    background: 'rgb(30, 137, 26)',
                    boxShadow: 'none',
                    outline: '1px solid white',
                    border: 'none',
                }
            }}
        >
            <FileDownload htmlColor='white' sx={{ width: '25px', height: '25px' }} />
        </Button>)
    };

    useEffect(() => {
        const paymentList = archivePaymentListGroupQuery?.data?.data;
        if (archivePaymentListGroupQuery.isSuccess &&
            (paymentList && paymentList?.length > 0)) {
            setselectedPaymentID(paymentList[paymentList?.length - 1]?.paymentID);
        }


    }, [archivePaymentListGroupQuery.data, archivePaymentListGroupQuery.isSuccess, archivePaymentListGroupQuery.isError])



    const receiptUploadButtonTemplate = (data, index) => {

        const sasToken = hospitalTokenListQuery?.data?.data?.find(hosp => data?.userID === hosp.userID)?.sasToken;
        const fileURL = `${data?.receiptData?.blobUrl}?${sasToken}`;

        return (data?.receiptData ? <DownloadButton key={`downloadReceiptButton-${data?.RBKCaseNo}-${index}`} href={fileURL} className='DocDownloadButton'
            disableRipple={true}
            sx={{ background: 'rgb(30, 94, 28)', '&:hover': { background: 'rgb(30, 137, 26)' } }}
        >
            <FileDownload htmlColor='white' sx={{ width: '25px', height: '25px' }} />
            <Typography>
                {TextLanguage['Download'][lang]}
            </Typography>
        </DownloadButton> : <DownloadButton key={`UploadReceiptButton-${data?.RBKCaseNo}-${index}`} onClick={fileUploader(uploadReceiptMutation, uploadTypes, { selectedHospUpload: data?.userID })} className='DocDownloadButton'
            disableRipple={true}
            sx={{ background: '#0c4eb045', '&:hover': { background: '#0062f5a1' } }}
        >
            <FileUploadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
            {TextLanguage['Upload'][lang]}
        </DownloadButton>)
    };
    //    fileUploader(uploadReceiptMutation, '.pdf,.doc,.docx,image/*', { selectedHospUpload })

    const [paymentListModalOpen, setPaymentListModalOpen] = useState(false);
    const [modalPosition, setmodalPosition] = useState('100%');

    const [selectedArchiveHospitalID, setselectedArchiveHospitalID] = useState(null);



    const archivedPatientsQuery = useArchivedHospPatientsQuery({ paymentID: selectedPaymentID, selectedUserID: selectedArchiveHospitalID });

    useEffect(() => {

        if (archivedPatientsQuery.isSuccess && currentTab === 3) {
            setpatientList(archivedPatientsQuery.data.data);
        }
        if (archivedPatientsQuery.isError) {
            setsnackbarStatus(
                {
                    open: true,
                    message: `Error Fetching Patients`,
                    severity: 'error'
                }
            )
        }

    }, [archivedPatientsQuery.data, archivedPatientsQuery.isSuccess, archivedPatientsQuery.isError])

    useEffect(() => {
        setselectedArchiveHospitalID(null);
    }, [selectedPaymentID])

    const [selectedActiveHospitalID, setselectedActiveHospitalID] = useState(null);
    useEffect(() => {
        if (currentTab === 2 && selectedActiveHospitalID) {
            setpatientList(
                ActivePaymentQuery.data.data.patients.filter((patient) => patient.hospCases[0].userID === selectedActiveHospitalID)
            );
        }


    }, [selectedActiveHospitalID])



    const patientColumnsCondition = [0, 1].includes(currentTab) || (currentTab === 2 && !!selectedActiveHospitalID) || (currentTab === 3 && (!opMode || !!selectedArchiveHospitalID));
    const hospitalColumnsCondition = (currentTab === 2 && !selectedActiveHospitalID) || (currentTab === 3 && opMode && !selectedArchiveHospitalID)

    let currentListValue = null;
    /*
    patientColumnsCondition ?
                                patientList
                                :
                                (currentTab === 2) ?
                                    activePaymentList
                                    :
                                    archivePaymentListQuery?.data?.data?.paymentList ? archivePaymentListQuery?.data?.data?.paymentList : []
    */
    if (patientColumnsCondition) {
        currentListValue = patientList;
    } else if (currentTab === 2) {
        currentListValue = activePaymentList;
    } else if (currentTab === 3) {
        currentListValue = archivePaymentListQuery?.data?.data?.paymentList ? archivePaymentListQuery?.data?.data?.paymentList : [];
    } else if (currentTab === 4) {
        currentListValue = parsedPaymentListHospQuery?.data?.data?.aggregateDateList;
    }


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="App-main">

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                {opMode && <PaymentListCreator
                    lang={lang}
                    setsnackbarStatus={setsnackbarStatus}
                    paymentListModalOpen={paymentListModalOpen}
                    setPaymentListModalOpen={setPaymentListModalOpen}
                    modalPosition={modalPosition}
                    setmodalPosition={setmodalPosition}

                />}
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>

                    <NavigationBar
                        currentPage='payment'


                        lang={lang}
                        opMode={opMode}
                        archivePaymentListGroupQuery={archivePaymentListGroupQuery}
                        setselectedPaymentID={setselectedPaymentID}
                        selectedPaymentID={selectedPaymentID}

                        selectedFilterUserID={selectedFilterUserID}
                        setselectedFilterUserID={setselectedFilterUserID}
                        disablePaymentList={ActivePaymentQuery.isError}

                    />



                    <div className='dataTableContainer'>


                        <Backdrop sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loadingPatientsList || PaymentListPatientsQuery.isFetching || ActivePaymentQuery.isFetching}  >
                            <CircularProgress size={'4rem'} />
                        </Backdrop>

                        {(patientColumnsCondition) && <ContextMenu model={menuModel} ref={contextMenuRef} onHide={() => setrightselectedRow(null)} />}



                        <DataTable
                            onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                            contextMenuSelection={rightselectedRow}
                            onContextMenuSelectionChange={(e) => { (patientColumnsCondition) && setrightselectedRow(e.value); }}
                            id='divTable'
                            onSelectionChange={(e) => (patientColumnsCondition) && setselectedRows(e.value)}
                            ref={datatableRef}
                            selection={selectedRows}
                            selectionMode="multiple"
                            metaKeySelection={true}
                            dragSelection
                            stripedRows
                            scrollable
                            rowClassName={rowClass}
                            resizableColumns

                            onRowSelect={
                                (currentTab === 2 && !selectedActiveHospitalID) ? // ActivePaymentList
                                    (e) => {
                                        setselectedActiveHospitalID(e.data.userID);
                                    }
                                    : (currentTab === 3 && !selectedArchiveHospitalID) ? // Archive List - Hospital

                                        (e) => {
                                            setselectedArchiveHospitalID(e.data.userID);
                                        }
                                        : // Patient
                                        null
                            }

                            header={
                                <DataTablePaymentHeader
                                    setmodalPosition={setmodalPosition}
                                    setPaymentListModalOpen={setPaymentListModalOpen}
                                    selectedRows={selectedRows}
                                    setActivePaymentList={setActivePaymentList}
                                    setsnackbarStatus={setsnackbarStatus}
                                    setFilterToggle={setFilterToggle}
                                    filterToggle={filterToggle}
                                    lang={lang}
                                    setSearchFilter={setSearchFilter}
                                    searchFilter={searchFilter}

                                    ActivePaymentQuery={ActivePaymentQuery}
                                    ActivePaymentState={ActivePaymentState}

                                    archivePaymentListQuery={archivePaymentListQuery}

                                    parsedPaymentListGroupQuery={parsedPaymentListGroupQuery}
                                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                                    parsedSelectedHospital={parsedSelectedHospital}
                                    setparsedSelectedHospital={setparsedSelectedHospital}

                                    selectedArchiveHospitalID={selectedArchiveHospitalID}
                                    selectedActiveHospitalID={selectedActiveHospitalID}
                                    setselectedArchiveHospitalID={setselectedArchiveHospitalID}
                                    setselectedActiveHospitalID={setselectedActiveHospitalID}

                                    opMode={opMode}

                                />
                            }
                            footer={
                                <DataTablePaymentFooter

                                    selectedPaymentID={selectedPaymentID}
                                    patientDashboard={patientDashboard}

                                    lang={lang}
                                    setlang={setlang}
                                    parsedPaymentListGroupQuery={parsedPaymentListGroupQuery}
                                    parsedPaymentListHospQuery={parsedPaymentListHospQuery}
                                    parsedSelectedHospital={parsedSelectedHospital}
                                    setparsedSelectedHospital={setparsedSelectedHospital}

                                    opMode={opMode}
                                />
                            }
                            showGridlines
                            size="small"
                            dataKey="_id"
                            className="DataGrid"
                            value={currentListValue}

                            tableStyle={{ width: 'auto', minWidth: '50rem' }}
                        >

                            {patientColumnsCondition &&
                                <Column field="LDMCaseNo" header={`LDM ${TextLanguage["RefNo"][lang]}`}
                                    style={{ width: '4%', minWidth: '90px' }}
                                    body={formatLDMCaseNo}
                                />
                            }
                            {patientColumnsCondition &&
                                <Column field="caseNumber" header={`RBK ${TextLanguage["RefNo"][lang]}`}
                                    style={{ width: '2%', minWidth: '110px !important' }}
                                    body={formatCaseNo({ confirmedUserID, opMode: true, enableBadge: false })} />
                            }
                            {patientColumnsCondition &&
                                <Column field="GOPPriceUSD" header={GOPPriceGroupHeader({ lang })}
                                    headerStyle={{ width: '0.01%', minWidth: '110px !important' }}
                                    style={{ width: '0.01%', minWidth: '380px' }}
                                    body={formatGOPPriceGroup} />
                            }

                            {patientColumnsCondition &&
                                <Column field="name" header={`${TextLanguage["PatientName"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    body={formatPatientName}
                                />
                            }
                            {patientColumnsCondition &&
                                <Column field="admissionDate" header={`${TextLanguage["AdmissionDate"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    body={formatDateAdm} />
                            }
                            {currentTab === 1 &&
                                <Column field="InvoiceDate" header={`${TextLanguage["PaymentDeadline"][lang]}`}
                                    style={{ width: '4%', minWidth: '100px' }}
                                    body={formatDateDeadline({ lang })} />
                            }
                            {patientColumnsCondition &&
                                <Column field="InsuranceCo" header={`${TextLanguage["InsuranceCompany"][lang]}`}
                                    style={{ width: '5%', minWidth: '100px' }}
                                    body={formatInsuranceCompany(insCoListQuery?.data?.data)} />
                            }
                            {patientColumnsCondition && currentTab !== 3 &&
                                <Column field="hospCases.userID" header={`${TextLanguage["HospitalClinic"][lang]}`}
                                    style={{ width: '9%', minWidth: '100px' }}
                                    body={formatHospitalUserID(hospitalUserListQuery?.data?.data)} />
                            }
                            {patientColumnsCondition && [2, 3].includes(currentTab) &&
                                <Column field="hospCases.files" header={`${TextLanguage["Invoice"][lang]}`}
                                    style={{ width: '1%', minWidth: '100px', maxWidth: '100px' }}
                                    body={invoiceButtonTemplate} />
                            }

                            {
                                /*
                            currentTab === 0 &&
                                <Column field="docDownload" header={`${TextLanguage["Documents"][lang]}`}
                                    style={{ width: '1%', minWidth: '70px !important' }}
                                    bodyStyle={{
                                        padding: '0px !important', margin: '5px', background: 'rgb(49,80,125)', boxShadow: 'inset 0px 0px 8px 2px rgba(0,0,0,0.25)'
                                    }}
                                    body={formatDocDownloader} />
                                */
                            }
                            {hospitalColumnsCondition &&
                                <Column field="userID" header={`${TextLanguage["HospitalClinic"][lang]}`}
                                    style={{ width: '9%', minWidth: '100px' }}
                                    sortable
                                    body={formatHospitalUserIDPayments(hospitalUserListQuery?.data?.data)} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="GOPPriceUSD" header={`${TextLanguage["GOPPrice"][lang]} (USD)`}
                                    style={{ width: '2%', minWidth: '100px' }}
                                    body={formatGOPPriceGroupPayments} />
                            }

                            {hospitalColumnsCondition &&
                                <Column field="GOPPriceUSD" header={`Avg ${TextLanguage["GOPPrice"][lang]} (USD)`}
                                    style={{ width: '2%', minWidth: '100px' }}
                                    body={formatAvgUSDPayments} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="totalCount" header={`${TextLanguage["TotalPatients"][lang]}`}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    body={formatPatientCountPayments} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="Outpatient" header={"Outpatient"}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    body={formatOutPatientCountPayments} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="Outpatient (In Clinic)" header={"Outpatient (In Clinic)"}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    body={formatOutPatientInClinicCountPayments} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="Inpatient" header={"Inpatient"}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    body={formatInPatientCountPayments} />
                            }
                            {hospitalColumnsCondition &&
                                <Column field="Receipt" header={"Receipt"}
                                    style={{ width: '1%', minWidth: '3rem' }}
                                    body={receiptUploadButtonTemplate} />
                            }

                            {currentTab === 4 &&
                                <Column field="totalCount" header={`Odeme Tarihi`}
                                    style={{ width: '0.1%', minWidth: '2rem' }}
                                    body={(hospCaseRow) => {
                                        return (
                                            <Typography className='RowCountText'>
                                                {`${hospCaseRow.paymentListDate}`}
                                            </Typography>
                                        );
                                    }} />

                            }
                            {currentTab === 4 &&
                                <Column field="totalCount" header={`${TextLanguage["TotalPatients"][lang]}`}
                                    style={{ width: '0.1%', minWidth: '2rem' }}
                                    body={formatPatientCountPayments} />

                            }
                              {currentTab === 4 &&
                                <Column  header={`Avg ${TextLanguage["GOPPrice"][lang]} (USD)`}
                                    style={{ width: '0.1%', minWidth: '100px' }}
                                    body={formatAvgUSDPayments} />

                            }
                               {currentTab === 4 &&
                                <Column header={`Avg ${TextLanguage["GOPPrice"][lang]} (TL)`}
                                    style={{ width: '0.1%', minWidth: '100px' }}
                                    body={formatAvgTLPayments} />

                            }


                            {currentTab === 4 &&
                                <Column  header={`${TextLanguage["GOPPrice"][lang]}`}
                                    style={{ width: '2%', minWidth: '100px' }}
                                    body={formatGOPPriceGroupPayments} />

                            }





                        </DataTable>


                    </div>
                </div>




            </div>

        </LocalizationProvider>










    );
}

export default PaymentPage;








