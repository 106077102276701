import React, { useEffect, useState } from 'react';



import {
    Button,
    Typography,
    IconButton,
    TextField,
    Select,
    FormControl,
    MenuItem,
    Chip
} from '@mui/material';

import DateFilter from '../DateFilter';
import TextFilter from '../TextFilter';

import TextLanguage from '../../../data/TextLanguage.json';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useProcessCaseMutation } from '../../../hooks/useProcessCaseMutation';
import { useCompletePaymentListMutation } from '../../../hooks/useCompletePaymentListMutation';

import { PaymentListHeader } from './PaymentListHeader';

import { useUploadExcelMutation } from '../../../hooks/useUploadExcelMutation';
import { FilterSelector } from './FilterSelector';
import { useQueryClient } from '@tanstack/react-query';
import { fileUploader } from '../../../functions/fileUploader';
import { useSearchParams } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line';
import { NivoTheme } from '../../Dashboard/NivoTheme';

const payrollTypeList = [
    { value: 0, label: 'Type A' },
    { value: 1, label: 'Type B' },

]

const exrColor = '#f4452a';
const usdColor = '#9cf42a';
const countColor = '#ffffff';


export const DataTablePaymentHeader = ({
    setFilterToggle, filterToggle, lang, setSearchFilter, searchFilter, selectedRows,
    setActivePaymentList, setsnackbarStatus,

    ActivePaymentQuery, ActivePaymentState,

    parsedPaymentListHospQuery,


    archivePaymentListQuery,
    setPaymentListModalOpen, setmodalPosition, selectedArchiveHospitalID,
    setselectedArchiveHospitalID, selectedActiveHospitalID, setselectedActiveHospitalID,
    opMode
}) => {


    const queryClient = useQueryClient();
    const InsuranceCompanyListData = queryClient.getQueryData(['insCoList']);
    const hospitalUserListData = queryClient.getQueryData(['hospitalUserList']);

    const [searchParams, setSearchParams] = useSearchParams();

    const currentTab = parseInt(searchParams.get('currentTab')) || 0;

    const currentPage = 'payment';
    const ProcessMutation = useProcessCaseMutation({ setsnackbarStatus, setActivePaymentList });
    const CompleteListMutation = useCompletePaymentListMutation({ setsnackbarStatus, setActivePaymentList, setPaymentListModalOpen });

    const handleCommitProcessed = async () => { // Move to processed after all files are uploaded to LDM
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'moveToProcessed' });
    }
    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadExcelMutation = useUploadExcelMutation({ setsnackbarStatus, setUploadProgress });

    const handleCreateActivePaymentList = async () => { // Create Active Payment List for Hospitals
        setPaymentListModalOpen(true);
        setmodalPosition('0%');
        /*
        const updaterData = selectedRows;
        ProcessMutation.mutate({ updaterData: updaterData, route: 'createActivePaymentList' });
*/
    };

    const handleCompletePayment = () => { //  Finish Payments on Active Payment List => Archive current Active Payment List
        CompleteListMutation.mutate({});
    }


    const onDateFilter = (filter) => {
        setSearchFilter({ ...searchFilter, ...filter });
        console.log(searchFilter);
    };

    const onTextFilter = (field) => (filter) => {
        setSearchFilter({ ...searchFilter, ...{ [field]: filter } });
        console.log(searchFilter);
    }

    const [parsedPaymentGraphData, setParsedPaymentGraphData] = useState([]);
    const [graphMaxValues, setGraphMaxValues] = useState({
        totalCount: 1,
        GOPTotalUSD: 1,
        GOPTotalTL: 1,
        EXR: 1
    });

    useEffect(() => {
        if (parsedPaymentListHospQuery.isSuccess) {

            const aggrList = parsedPaymentListHospQuery?.data?.data?.aggregateDateList.map((record) => {
                return {
                    ...record,

                    totalCount: record.totalCount,
                    EXR: record.GOPTotalTL / (record.GOPTotalUSD * 100),
                    GOPTotalTL: record.GOPTotalTL
                }
            });
            const maxValues = aggrList.reduce((max, item) => {
                return {
                    totalCount: Math.max(max.totalCount, item.totalCount),
                    GOPTotalUSD: Math.max(max.GOPTotalUSD, item.GOPTotalUSD),
                    GOPTotalTL: Math.max(max.GOPTotalTL, item.GOPTotalTL),
                    EXR: Math.max(max.EXR, item.EXR)
                };
            }, { GOPTotalUSD: -Infinity, GOPTotalTL: -Infinity, totalCount: -Infinity, EXR: -Infinity });

            setGraphMaxValues(maxValues);

            const normalizedAggrList = aggrList.map((record) => {
                return {
                    ...record,

                    totalCount: record.totalCount / maxValues.totalCount,
                    GOPTotalUSD: record.GOPTotalUSD / maxValues.GOPTotalUSD,
                    GOPTotalTL: record.GOPTotalTL / maxValues.GOPTotalTL,
                    EXR: record.EXR / maxValues.EXR
                }

            });
            console.log(normalizedAggrList)

            setParsedPaymentGraphData([
                {
                    id: 'Total USD',
                    color: usdColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: record["paymentDateParsed"], y: record["GOPTotalUSD"] }
                    })
                },
                {
                    id: 'Exchange Rate',
                    color: exrColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: record["paymentDateParsed"], y: record["EXR"] }
                    })
                },
                {
                    id: 'Total Count',
                    color: countColor,
                    data: normalizedAggrList?.map((record) => {
                        return { x: record["paymentDateParsed"], y: record["totalCount"] }
                    })
                }

            ]);

        }
        console.log(graphMaxValues);

    }, [parsedPaymentListHospQuery.isSuccess, parsedPaymentListHospQuery.data])



    /*
        const [payrollType, setPayrollType] = useState(payrollTypeList[0]?.value);
        const handlePayrollTypeSelect = (e) => {
            setPayrollType(e.target.value);
        }

    */

    return <div className='datatableHeader' id='divDTHeader'>



        <div className='datatableHeaderFilterRowContainer'>



            {([0, 1].includes(currentTab)) && <IconButton onClick={() => { setFilterToggle(!filterToggle); }}> <FilterAltIcon fontSize='large' htmlColor='white' /></IconButton>}

            {([0, 1].includes(currentTab)) && <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignContent: 'center', alignItems: 'center' }}>
                {hospitalUserListData?.status === 200 && <FilterSelector
                    filterLabel={"hospID"}
                    selectLabel={TextLanguage["FilterByHospital"][lang]}
                    optionsList={hospitalUserListData?.data}
                    setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter}
                />}
                {InsuranceCompanyListData?.status === 200 && <FilterSelector
                    filterLabel={"InsCo"}
                    selectLabel={TextLanguage["FilterByInsurance"][lang]}
                    optionsList={InsuranceCompanyListData?.data}
                    setSearchFilter={setSearchFilter}
                    searchFilter={searchFilter}
                />}



            </div>}



            {currentTab === 0 && <Button onClick={fileUploader(uploadExcelMutation, '.xlsx,.xls,.xlsm,.xlsb')}
                className='DocDownloadButton'
                disableRipple={true}
                sx={{
                    height: 'auto', minWidth: '25px', minHeight: '25px', p: '0.5rem 1rem', m: '0px', zIndex: 355,
                    background: '#0c4eb045', border: 'none', outline: '1px solid #787878', boxShadow: 'none', color: 'white',
                    gap: '0.25rem',
                    '&:hover': {
                        background: '#0062f5a1',
                        boxShadow: 'none',
                        outline: '1px solid white',
                        border: 'none',
                    }
                }}
            >
                <Typography>
                    {TextLanguage["Payroll"][lang]}
                </Typography>
                <FileUploadIcon htmlColor='white' sx={{ width: '25px', height: '25px' }} />
            </Button>

            }



            {
                [2, 3, 4].includes(currentTab) &&
                <PaymentListHeader
                    lang={lang}
                    currentList={
                        currentTab === 2 ? ActivePaymentQuery?.data?.data
                            :
                            currentTab === 3 ? archivePaymentListQuery?.data?.data
                                :
                                parsedPaymentListHospQuery?.data?.data
                    }
                    selectedItem={
                        currentTab === 2 ? selectedActiveHospitalID
                            :
                            currentTab === 3 ? selectedArchiveHospitalID
                                :
                                null
                    }
                    setSelectedItem={
                        currentTab === 2 ? setselectedActiveHospitalID
                            :
                            currentTab === 3 ? setselectedArchiveHospitalID
                                :
                                null
                    }

                    opMode={opMode}

                />
            }




            {currentTab === 0 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCommitProcessed} className="CommitButton">
                    <Typography>
                        {TextLanguage["CommitAsProcessed"][lang]}
                    </Typography>
                </Button>
            </div>}
            {currentTab === 1 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCreateActivePaymentList} className="CommitButton" disabled={(ActivePaymentQuery.isSuccess && ActivePaymentQuery?.data?.data?.paymentList?.length > 0)}>
                    <Typography>
                        {TextLanguage["CreatePaymentList"][lang]}
                    </Typography>
                </Button>
            </div>}


            {currentTab === 2 && <div className="CommitButtonContainer">
                <Button sx={{ borderRadius: 0 }} onClick={handleCompletePayment} className="CommitButton"
                    disabled={!ActivePaymentState}
                >
                    <Typography>
                        {TextLanguage["CompletePaymentList"][lang]}
                    </Typography>
                </Button>
            </div>}


            {(currentTab === 4) && (parsedPaymentListHospQuery?.isSuccess) &&
                <div className='paymentListHeaderWrapper'

                    style={{
                        width: '600px', height: '56px', position: 'relative'

                    }}>

                    <ResponsiveLine
                        data={parsedPaymentGraphData}
                        animate


                        curve="monotoneX"
                        //colors={{ datum: 'color' }}
                        enableSlices="x"

                        pointColor={{ theme: 'background' }}


                        margin={{
                            bottom: 8,
                            left: 4,
                            right: 4,
                            top: 8
                        }}
                        theme={NivoTheme}
                        colors={{ datum: 'color' }}
                        enableGridX={true}
                        enableGridY={false}


                        lineWidth={1}
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 'auto',
                            stacked: false,
                            reverse: false
                        }}

                        sliceTooltip={({ slice }) => {
                            const date = slice.points[0].data.xFormatted.split('T')[0].split('-');
                            const day = date[2];
                            const month = date[1];
                            const year = date[0];
                            // Calculate the tooltip position

                            return (
                                <div
                                className='miniGraphTooltip'

                                >
                                    <div style={{ marginBottom: '5px', width: '100%', padding: '8px', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant='h6' style={{ color: 'white' }}>
                                            {`${day - 1}/${month}/${year}`}
                                        </Typography>
                                    </div>
                                    <div className='miniGraphTooltipTextContainer' >

                                        <div className='miniGraphTooltipTextLine'>
                                            <div className='miniGraphTooltipMarker' style={{ background: countColor }}/>

                                            <Typography variant='body1' style={{ color: 'white' }}>
                                                {`${(slice.points[0].data.yFormatted * graphMaxValues.totalCount).toFixed(0)} ${TextLanguage["Patients"][lang]}`}
                                            </Typography>
                                        </div>

                                        <div className='miniGraphTooltipTextLine'>
                                            <div className='miniGraphTooltipMarker' style={{ background: usdColor }}/>
                                            <Typography variant='body1' style={{ color: 'white' }}>
                                                {`$${(slice.points[2].data.yFormatted * graphMaxValues.GOPTotalUSD).toFixed(0)}`}
                                            </Typography>
                                        </div>

                                        <div className='miniGraphTooltipTextLine'>
                                            <div className='miniGraphTooltipMarker' style={{ background: exrColor }}/>


                                            <Typography variant='body1' style={{ color: 'white' }}>
                                                {`${(slice.points[1].data.yFormatted * graphMaxValues.EXR).toFixed(1)} ${TextLanguage["EXR"][lang]} `}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            );
                        }}

                        xFormat="time:%Y-%m-%dT%H:%M:%S.%LZ"
                        xScale={{
                            format: '%Y-%m-%dT%H:%M:%S.%LZ',
                            precision: 'day',
                            type: 'time',
                        }}



                        axisBottom={false}
                        axisLeft={false}
                        axisTop={null}
                        axisRight={null}


                    />


                </div>}







        </div>

        {(filterToggle) ? null : <div className='datatableHeaderFilterRowContainer' style={{ justifyContent: 'flex-start' }}>
            <TextFilter labelName={`RBK ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('caseNumber')} />
            <TextFilter labelName={`LDM ${TextLanguage["RefNo"][lang]}`} onFilter={onTextFilter('LDMCaseNo')} />
            <TextFilter labelName={TextLanguage["FilterByName"][lang]} onFilter={onTextFilter('name')} />
            <DateFilter onFilter={onDateFilter} />

        </div>}
    </div>
}




/*

<div className='PayrollUploaderContainer'>
                <FormControl sx={{ minWidth: 150 }}>
        <InputLabel shrink={true} id="payrollSelectorLabel">
            <Typography>
                {`${TextLanguage["PayrollInsuranceType"][lang]}`}
            </Typography>
        </InputLabel>
        <Select
            value={payrollType}
            onChange={handlePayrollTypeSelect}

            sx={{ width: 250, maxHeight: 45, textAlign: 'left' }}
        >
            {payrollTypeList?.map((option) => (
                <MenuItem key={`payrollType-${option.value}`} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
*/
