import { Typography } from '@mui/material';
import React from 'react';
import TextLanguage from '../../../data/TextLanguage.json';
import { useQueryClient } from '@tanstack/react-query';

const DividerElement = () => (<svg height="35" width="6">
<line x1="0" y1="0" x2="0" y2="50" stroke="#78787887" strokeWidth="6" />
</svg>)


export const PaymentListDataDisplay = ({
    lang, opMode,
    totalCount,
    USDValue,
    TLValue,






}) => {


    const queryClient = useQueryClient();

    const exchangeRateData = queryClient.getQueryData(['exchangeRate']);
    const exchangeRate = exchangeRateData?.data?.BanknoteSellingValue;

    const avgExrRate = parseFloat((TLValue / USDValue).toFixed(2));

    const parsedUSDPrice = parseFloat(USDValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");


    const parsedPrice = parseFloat(TLValue).toString().split('.');
    const priceStringAfterComma = parsedPrice[1];
    const priceStringSplit = parsedPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const parsedAvgUSDPrice = parseFloat(USDValue / totalCount).toFixed(2).toString().split('.');
    const avgUSDPriceStringAfterComma = parsedAvgUSDPrice[1];
    const avgUSDPriceStringSplit = parsedAvgUSDPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    const parsedPriceAvg = parseFloat(TLValue/totalCount).toFixed(2).toString().split('.');
    const priceAvgStringAfterComma = parsedPriceAvg[1];
    const priceAvgStringSplit = parsedPriceAvg[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");


    return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}>

            <div style={{ padding: '0px 20px 0px 10px' }}>
                <Typography sx={{
                    color: 'white',
                    fontWeight: 'bolder',
                    fontSize: '1.5rem',
                }}

                >
                    <span style={{ color: 'lightgreen', margin: '0px 5px' }}>
                        {`${(totalCount)} ${parseInt(totalCount) > 1 ? TextLanguage["Patients"][lang] : TextLanguage["Patient"][lang]} `}
                    </span>
                    {opMode && <>

                        <DividerElement/>
                        <span style={{ color: 'white', margin: '0px 5px' }}>
                            {`Total:`}
                        </span>
                        <span style={{ color: 'lightgreen', margin: '0px 5px' }}>
                        {parsedUSDPrice ?  `$${parsedUSDPrice}` : `$0` }
                        </span>
                        <span style={{ color: 'white', margin: '0px 5px' }}>
                            {`-`}
                        </span>
                        <span style={{ color: 'lightgreen', margin: '0px 5px' }}>
                        {priceStringAfterComma ?  `${priceStringSplit},${priceStringAfterComma} ₺` : `${priceStringSplit} ₺` }
                    </span>


                    </>}
                    <DividerElement/>
                    <span style={{ color: 'white', margin: '0px 5px' }}>
                        {`Avg:`}
                    </span>
                    <span style={{ color: 'lightgreen', margin: '0px 5px' }}>
                        {avgUSDPriceStringAfterComma ?  `$${avgUSDPriceStringSplit},${avgUSDPriceStringAfterComma}` : `${avgUSDPriceStringSplit} ₺` }
                        </span>
                    {opMode && <>
                        <span style={{ color: 'white', margin: '0px 5px' }}>
                        {`-`}
                    </span>
                    <span style={{ color: 'lightgreen', margin: '0px 5px' }}>
                        {priceAvgStringAfterComma ?  `${priceAvgStringSplit},${priceAvgStringAfterComma} ₺` : `${priceStringSplit} ₺` }
                    </span>
                    </>}
                </Typography>
            </div>
            {opMode && <>

                <DividerElement/>

                <div style={{ padding: '0px 20px' }}>
                    <Typography sx={{
                        color: ((parseFloat((TLValue) / USDValue).toFixed(2) - exchangeRate < 0)) ? 'lightgreen' : 'red',
                        fontWeight: 'bolder',
                        fontSize: '0.75rem',
                    }}
                    >{`${TextLanguage["AvgExrRate"][lang]}: ${avgExrRate}`}</Typography>
                    <Typography sx={{
                        color: 'white',
                        fontWeight: 'bolder',
                        fontSize: '0.75rem',
                    }}
                    >{`${TextLanguage["CurrentExrRate"][lang]}: ${parseFloat(exchangeRate).toFixed(2)}`}</Typography>

                </div>
            </>}

        </div>
    );
}
