
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";
import { useNavigate } from "react-router-dom";

export const useParsedPaymentListHospQuery = ({selectedHospital}) => {

    const navigate = useNavigate();

    async function fetchParsedPayment({ queryKey }) {
        const [_key, selectedHospital] = queryKey;

        return await axios.get(`${config.API_URL}/payment/PaymentPatientListHosp?selectedHospital=${selectedHospital}`, {
            withCredentials: true,
        })

    }



    return useQuery(
        {
            queryKey: ['ParsedPaymentListHosp', selectedHospital],
            enabled: selectedHospital !== null,
            queryFn: fetchParsedPayment,
            staleTime: 1000 * 60 * 5, // 5 minutes
            refetchInterval: 1000 * 60 * 5, // 5 minutes

        }
    )





}


